.sidebar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sidebar-logo {
    width: 30%;
}

.sidebar-content,
.tag-list-header {
    /*align-self: flex-start;*/
    align-self: stretch;
}

.tag-list-container {
    align-self: flex-start;
    margin-block-start: 0.25em;
    margin-block-end: 1em;
    display: flex;
    justify-content: flex-start;
}

.tag-list > .tag-list-item {
    margin: 0 5px 5px 0;
}
