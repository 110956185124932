.card-item {
    padding: 20px;
    border-radius: 5px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
}



.card-icon {
    flex: 0 0 200px;
    text-align: center;
}

.card-icon > img {
    max-width: 200px;
    max-height: 200px;
    cursor: pointer;
}


.card-content {
    margin-left: 0;
    margin-top: 5px;
}

.card-title {
    margin-top: 0;
    margin-bottom: 5px;
    text-align: center;
}

.card-owner {
    margin-top: 0;
    margin-bottom: 5px;
    text-align: center;
}

.card-link {
    padding: 5px;
    background-color: #e81e2b;
    border: 1px solid #e81e2b;
    border-radius: 3px;
    text-decoration: none;
    margin-bottom: 5px;
    color: #ffffff;
}

.card-link:hover {
    background-color: #AE171D;
}

.card-link:visited {
    color: #ffffff;
}

@media screen and (min-width: 670px) {
    .card-item {
        display: flex;
    }

    .card-content {
        margin-left: 20px;
        margin-top: 0;
    }

    .card-owner,
    .card-title {
        text-align: left;
    }

}
