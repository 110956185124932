
main, section, aside, footer {
  margin: 0;
  padding: 20px;
  /*border: 1px solid #000000;*/
  color: #555555;
  background-color: #fbfbfb;
  height: 100%;
}

.page-sidebar {     /* For small screens, sidebar is on top so show bottom border */
  flex: 0 0 30%;
  padding-right: 0;
  padding-bottom: 2px;
  background: linear-gradient(to right, #1a4b23, #fbfbfb);
}

@media screen and (min-width: 737px) {
  #root {
    height: 100vh;
    overflow-y: hidden;
  }
  .main-content {
    height: calc(100vh - 40px);
  }

  .flex-container {
    display: flex;
    height: 100%;
  }

  .page-sidebar {     /* For med/large screens, sidebar is on side so show right border */
    padding-bottom: 0;
    padding-right: 2px;
    background: linear-gradient(to bottom, #1a4b23, #fbfbfb);
  }

  .main-feed {
    height: 100%;
  }

  .page-content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.page-sidebar-content {
  background-color: #fbfbfb;
  height: 100%;
}

.page-content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.wrapper {
  margin: auto;
  max-width: 75em;
}
